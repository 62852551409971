import React, { useState, useEffect, useRef } from 'react';
import { Upload, Sliders, Loader, User, Palette, Scissors, Shirt, Download, Camera } from 'lucide-react';
import { Container, Row, Col, Card, Form, Button, Alert, Modal, Toast, Tabs, Tab, Badge } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';

function App() {
  const [step, setStep] = useState(1);
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [analysisType, setAnalysisType] = useState('face');
  const [isLoading, setIsLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [outTradeNo, setOutTradeNo] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [activeTab, setActiveTab] = useState('description');
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  const lines = [
    "你了解真实的自己吗？",
    "你的另一面是？",
    "现在",
    "用一张照片",
    "揭秘你隐藏的魅力"
  ];

  useEffect(() => {
    if (currentLineIndex < lines.length) {
      let index = 0;
      const timer = setInterval(() => {
        if (index <= lines[currentLineIndex].length) {
          setDisplayedText(lines[currentLineIndex].slice(0, index));
          index++;
        } else {
          clearInterval(timer);
          setTimeout(() => {
            setDisplayedText('');
            setCurrentLineIndex(prevIndex => prevIndex + 1);
          }, 1000); // 等待1秒后清除文字，开始下一行
        }
      }, 100); // 每100毫秒打一个字符

      return () => clearInterval(timer);
    } else {
      setAnimationComplete(true);
      setStep(1);
    }
  }, [currentLineIndex]);

  useEffect(() => {
    const cursorTimer = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500); // 光标每500

    return () => clearInterval(cursorTimer);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        const base64String = reader.result.split(',')[1];
        setImage(base64String);
        setStep(2);
      };
      reader.readAsDataURL(file);
    }
  };

  const analyzeImage = () => {
    if (!image) {
      alert('请先选择一张图片');
      return;
    }
    setIsLoading(true);
    fetch('/api/analyze-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ type: analysisType, image_data: image }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { 
            console.error('Server error:', err);
            throw new Error(err.error || '服务器错误');
          });
        }
        return response.json();
      })
      .then(data => {
        console.log('Analysis result:', data);
        if (data.error) {
          throw new Error(data.error);
        }
        setAnalysisResult(data);
        setStep(3);
        console.log('Step updated to:', 3);
      })
      .catch(error => {
        console.error('Error analyzing image:', error);
        setAnalysisResult({ 
          error: error.message || '未知错误',
          details: error.stack || '无详细信息'
        });
        setStep(3);  // 即使出错也切换到结果页面
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetDemo = () => {
    setStep(1);
    setImage(null);
    setPreviewUrl(null);
    setAnalysisType('face');
    setAnalysisResult(null);
  };

  const resultRef = useRef(null);

  const saveAsImage = () => {
    const element = document.querySelector('.analysis-result');
    if (element) {
      const scale = 2; // 增加清晰度
      const width = element.offsetWidth;
      // 创建一个新的div来包装分析结果
      const wrapper = document.createElement('div');
      wrapper.style.width = `${width}px`;
      wrapper.style.padding = '20px';
      wrapper.style.background = 'white';
      wrapper.appendChild(element.cloneNode(true));

      // 将包装器添加到body中
      document.body.appendChild(wrapper);

      html2canvas(wrapper, {
        backgroundColor: '#ffffff',
        scale: scale,
        logging: false,
        useCORS: true,
        width: width,
        windowWidth: width,
        onclone: (clonedDoc) => {
          const clonedWrapper = clonedDoc.body.lastChild;
          clonedWrapper.style.width = `${width}px`;
          
          // 调整克隆元素中的样式
          const clonedElement = clonedWrapper.firstChild;
          clonedElement.style.width = '100%';
          clonedElement.style.maxWidth = 'none';
          
          // 确保所有文本元素都有正确的样式
          const textElements = clonedWrapper.querySelectorAll('p, h3, span, div');
          textElements.forEach(el => {
            el.style.whiteSpace = 'normal';
            el.style.wordWrap = 'break-word';
            el.style.width = '100%';
          });

          // 调整图片和描述的布局
          const imageAndDesc = clonedWrapper.querySelector('.image-and-description');
          if (imageAndDesc) {
            imageAndDesc.style.display = 'flex';
            imageAndDesc.style.flexDirection = 'row';
            imageAndDesc.style.alignItems = 'flex-start';
          }

          // 调整标签和分析部分的布局
          const tagsAndAnalysis = clonedWrapper.querySelector('.tags-and-analysis');
          if (tagsAndAnalysis) {
            tagsAndAnalysis.style.display = 'flex';
            tagsAndAnalysis.style.flexDirection = 'column';
          }

          // 确保标签保持横向排列
          const tagGroups = clonedWrapper.querySelectorAll('.tag-group');
          tagGroups.forEach(group => {
            const tags = group.querySelector('.tags');
            if (tags) {
              tags.style.display = 'flex';
              tags.style.flexDirection = 'row';
              tags.style.flexWrap = 'wrap';
              tags.style.gap = '0.5rem';
            }
          });
        }
      }).then(canvas => {
        const image = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.download = 'FaceInsight-analysis.png';
        link.href = image;
        link.click();

        // 移除临时创建的包装器
        document.body.removeChild(wrapper);
      }).catch(error => {
        console.error('Error saving image:', error);
        showToastMessage('保存图片时发生错误，请稍后再试。');
      });
    } else {
      console.error('Analysis result element not found');
      showToastMessage('无法找到分析结果元素，请稍后再试。');
    }
  };

  const handleStartAnalysis = async () => {
    try {
      console.log('开始创建支付订单...');
      setIsLoading(true);
      const response = await axios.post('/api/create_payment');
      console.log('支付订单创建响应:', response.data);
      if (response.data.success) {
        setPaymentUrl(response.data.qr_code_url);
        setOrderNumber(response.data.out_trade_no);
        setShowPaymentModal(true);
        startPollingPaymentStatus(response.data.out_trade_no);
      } else {
        console.error('建支付订单失败:', response.data.message);
        showToastMessage('创建支付订单失败: ' + response.data.message);
      }
    } catch (error) {
      console.error('创建支付订单时发生错误:', error);
      showToastMessage('创建支付订单时发生错误，请稍后再试。');
    } finally {
      setIsLoading(false);
    }
  };

  const startPollingPaymentStatus = (orderNumber) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await axios.get(`/api/check_payment?out_trade_no=${orderNumber}`);
        if (response.data.success && response.data.paid) {
          clearInterval(pollInterval);
          setShowPaymentModal(false);
          showToastMessage('支付成功！正在开始分析...');
          analyzeImage();
        }
      } catch (error) {
        console.error('检查支付状态时发生错误:', error);
      }
    }, 3000); // 每3秒检查一次

    // 60秒后停止轮询
    setTimeout(() => {
      clearInterval(pollInterval);
    }, 60000);
  };

  const showToastMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  function AnalysisResult({ result, imageUrl }) {
    // 翻译函数保持不变
    const translateKey = (key) => {
      const translations = {
        'personality': '性格分析',
        'career': '职业分析',
        'relationships': '人际关系分析',
        'life_advice': '生活建议',
      };
      return translations[key] || key;
    };

    // 定义详细分析的顺序
    const analysisOrder = ['personality', 'career', 'relationships', 'life_advice'];

    return (
      <div className="analysis-result"> 
        <div className="result-content">
          <div className="image-and-description">
            <div className="image-preview-container">
              <img src={imageUrl} alt="Analyzed" className="analyzed-image" />
            </div>
            <div className="photo-description">
              <p>{result.photo_description}</p>
            </div>
          </div>
          
          <div className="tags-and-analysis">
            <div className="tags-section">
              <div className="tag-group">
                <h3><i className="fas fa-user-circle"></i> 性格标签</h3>
                <div className="tags">
                  {result.personality_tags.map((tag, index) => (
                    <span key={index} className="tag personality-tag">{tag}</span>
                  ))}
                </div>
              </div>
              
              <div className="tag-group">
                <h3>职业标签</h3>
                <div className="tags">
                  {result.career_tags.map((tag, index) => (
                    <span key={index} className="tag career-tag">{tag}</span>
                  ))}
                </div>
              </div>

              <div className="tag-group">
                <h3>年龄标签</h3>
                <div className="tags">
                  {result.age_tags && result.age_tags.map((tag, index) => (
                    <span key={index} className="tag age-tag">{tag}</span>
                  ))}
                </div>
              </div>
            </div>
            
            <hr className="analysis-divider" style={{ borderColor: '#a0a0a0', borderWidth: '2px' }} />

            <div className="detailed-analysis">
              {analysisOrder.map((key) => (
                result.detailed_analysis[key] && (
                  <div key={key} className="analysis-item">
                    <h3>{translateKey(key)}</h3>
                    <p>{result.detailed_analysis[key]}</p>
                  </div>
                )
              ))}
            </div>
            
            <p className="ai-generated-text" style={{ textAlign: 'left' }}>
            由 FaceInsight AI 分析生成 - createlab.com.cn
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="app-container">
      <div className="app-title-container">
        <h1 className="app-title gradient-text">FaceInsight</h1>
      </div>
      {!animationComplete && (
        <div className="intro-animation">
          <div className="typing-container">
            <p className="typing-text">{displayedText}</p>
            <span className={`cursor ${showCursor ? 'visible' : ''}`}></span>
          </div>
        </div>
      )}
      <Row className="justify-content-center">
        <Col xs={12} sm={10} md={8} lg={6}>
          {step > 0 && (
            <Card className="main-card">
              <Card.Body>
                {step === 1 && (
                  <div className="fade-in upload-section">
                    <p className="text-center mb-4">上传一张照片，让AI为您分析</p>
                    <Form.Group className="mb-3">
                      <Form.Label className="custom-file-upload">
                        <Upload className="mr-2" /> 选择照片上传
                        <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                      </Form.Label>
                    </Form.Group>
                  </div>
                )}

                {step === 2 && (
                  <div className="fade-in analysis-section">
                    <div className="image-preview mb-3">
                      <img src={previewUrl} alt="Preview" className="img-fluid" />
                    </div>
                    <Form.Group className="mb-3">
                      <div className="analysis-type-tabs">
                        <Button 
                          variant={analysisType === 'face' ? 'dark' : 'outline-dark'} 
                          onClick={() => setAnalysisType('face')}
                          active={analysisType === 'face'}
                        >
                          <User size={18} /> 面相分析
                        </Button>
                        <Button 
                          variant={analysisType === 'color' ? 'dark' : 'outline-dark'} 
                          onClick={() => setAnalysisType('color')}
                          active={analysisType === 'color'}
                        >
                          <Palette size={18} /> 个人色彩
                        </Button>
                        <Button 
                          variant={analysisType === 'hairstyle' ? 'dark' : 'outline-dark'} 
                          onClick={() => setAnalysisType('hairstyle')}
                          active={analysisType === 'hairstyle'}
                        >
                          <Scissors size={18} /> 发型建议
                        </Button>
                        <Button 
                          variant={analysisType === 'outfit' ? 'dark' : 'outline-dark'} 
                          onClick={() => setAnalysisType('outfit')}
                          active={analysisType === 'outfit'}
                        >
                          <Shirt size={18} /> 穿搭指南
                        </Button>
                      </div>
                    </Form.Group>
                    <Button onClick={handleStartAnalysis} disabled={isLoading} className="analyze-btn">
                      {isLoading ? (
                        <>
                          <Loader className="mr-2 spinner" size={18} /> 分析中...
                        </>
                      ) : (
                        <>
                          <Sliders className="mr-2" size={18} /> 开始分析
                        </>
                      )}
                    </Button>
                  </div>
                )}

                {step === 3 && (
                  <div className="fade-in result-page">
                    <Card className="result-card">
                      <Card.Body>

                        {analysisResult && analysisResult.error ? (
                          <Alert variant="danger">
                            <p>{analysisResult.error}</p>
                            {analysisResult.details && (
                              <details>
                                <summary>错误详情</summary>
                                <pre>{analysisResult.details}</pre>
                              </details>
                            )}
                          </Alert>
                        ) : analysisResult ? (
                          <AnalysisResult result={analysisResult} imageUrl={previewUrl} />
                        ) : (
                          <p>加载中...</p>
                        )}
                      </Card.Body>
                    </Card>
                    <div className="result-actions">
                      <Button variant="outline-dark" onClick={resetDemo} className="action-button">
                        重新开始
                      </Button>
                      <Button variant="outline-dark" onClick={saveAsImage} className="action-button">
                        <Download size={18} className="mr-2" /> 保存结果
                      </Button>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}

          <Alert variant="info" className="mt-4 fade-in custom-alert">
            <Alert.Heading>提示</Alert.Heading>
            <p>
              本应用仅作为娱乐用途，分析结果不应被视为专业意见。如需专业建，请咨询相关领域的专家。
            </p>
          </Alert>

          <footer className="mt-4 text-center text-muted fade-in">
            <p>© 2024 FaceInsight. All rights reserved.</p>
          </footer>
        </Col>
      </Row>
      
      <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>请完成支付</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>微信扫码支付，仅需0.01元。</p>
          <img src={paymentUrl} alt="支付维码" style={{width: '100%'}} />
          <p>订单号: {orderNumber}</p>
          <p>支付完成后，页面将自动跳转</p>
        </Modal.Body>
      </Modal>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999
        }}
      >
        <Toast.Header>
          <strong className="mr-auto">通知</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
}

export default App;